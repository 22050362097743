<template>
  <div
    class="bg-white border border-gray-200 sm:items-center extension_table_heading sticky top-0 flex flex-wrap justify-between"
  >
    <div class="col-start-2 col-end-3">
      <div class="mt-3 sm:mt-0 sm:ml-0 flex-1">
        <SearchFilter inputPlaceHolder="Search" v-model:search="searchNumb" />
      </div>
    </div>
  </div>
  <div class="pt-5 pr-10 pl-10 w-2/3 max-w-auto">
    <TablePagination
      :tableData="this.filterExtensionList()"
      :level="this.level"
    />
  </div>
</template>

<script>
import axios from "axios";

import TablePagination from "../TablePagination.vue";
import SearchFilter from "../SearchFilter.vue";
import { mapGetters } from "vuex";

const extensionsList = [
  { label: "Ex.217 - Guilherme", value: 217, type: "extension" },
  { label: "Ex.216 - John", value: 216, type: "extension" },
];

export default {
  name: "PBXExtension",
  data() {
    return {
      level: "extensions",
      pbxExtensions: [],
      pbxExtensionData: [],
      pbxExtensionsNames: [],
      searchNumb: "",
      searchName: "",
      showHeader: false,
      extensionsList,
    };
  },
  components: {
    TablePagination,
    SearchFilter,
  },
  methods: {
    mouseOver() {
      this.showHeader = !this.showHeader;
    },
    async getData() {
      const res = await axios.get(
        `${this.$cookie.getCookie("API")}/api/v1/pbxMap/test.3cx.fr`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.pbxExtensionData = res.data.extensions;
      for (const key in this.pbxExtensionData) {
        if (Object.hasOwnProperty.call(this.pbxExtensionData, key)) {
          this.pbxExtensions.push(this.pbxExtensionData[key]);
        }
      }
    },
    filterExtensionList() {
      return this.extensionsList.filter((extension) => {
        return String(extension.label)
          .toLowerCase()
          .includes(String(this.searchNumb).toLowerCase());
      });
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapGetters(["search"]),
  },
};
</script>

<style>
.extension_table_heading {
  padding: 10px 40px 10px 40px;
}
</style>
